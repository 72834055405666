import React from "react"
import styled from "styled-components"
import { ErrorMessageText, HeaderAndQuestionMarkWrapper } from "../sharedComponents/SharedComponents";

const TiltWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	cursor: pointer;
	height: calc(0.9 * (50vw - 38px));
	width: calc(50% - 8px);
	background-color: ${({ theme }) => theme.colors.formWhite};
	border-radius: 6px;
	border: 1px solid ${({ theme }) => theme.colors.formBlue};
	padding: 15px 10px 10px;
	margin: 0 0px 16px 0;
	max-height: 130px;
	text-align: center;
	&:hover {
		border: 1px solid ${({ theme }) => theme.colors.formFontDarkBlue};
	}
	&.active {
		border: 2px solid ${({ theme }) => theme.colors.formAlwisOrange};
		padding: 14px 10px 9px;
		box-shadow: 0 3px 5px 0 rgba(0,0,0,0.09) !important;
		background-color: ${({ theme }) => theme.colors.formAlwisOrangeLightOrange}
	}
	@media(min-width: 414px) {
		width: 107px;
		height: 96px;
		max-height: unset;
		margin: 0 16px 16px 0;
		padding: 15px 2px 10px;
		&.active{
			padding: 14px 2px 9px;
		}
	}
	@media(min-width: 768px) {
		width: 160px;
		height: 150px;
		margin: 0 20px 24px 0;
		padding: 20px 10px 10px;
		&.active {
			padding: 19px 10px 9px;
		}
	}
	@media(min-width: 1920px) {
		width: 170px;
		height: 150px;
		padding: ${({ NotBrand }) => NotBrand ? "30px 10px 15px" : "22px 10px 15px"};
		&.active {
			padding: ${({ NotBrand }) => NotBrand ? "29px 10px 14px" : "21px 10px 14px"};
		}
	}
`

const TiltIcon = styled.img`
	width: 44px;
	margin: 0 auto;
	@media(min-width: 340px) {
		width: 52px;
	}
	@media(min-width: 375px) {
		width: 56px;
	}
	@media(min-width: 414px) {
		width: 44px;
	}
	&.not-brand {
		width: 50px;
		@media(min-width: 340px) {
			width: 52px;
		}
		@media(min-width: 375px) {
			width: 56px;
		}
		@media(min-width: 414px) {
			width: 44px;
		}
		@media(min-width: 768px) {
			width: 55px;
		}
		@media(min-width: 1920px) {
			width: 60px;
			max-height: 65px;
		}
	}
	@media(min-width: 768px) {
		width: 70px;
	}
	@media(min-width: 1920px) {
		width: 70px;
	}
`

const TiltName = styled.p`
	font-size: 14px;
	line-height: 18px;
	font-weight: ${({ theme }) => theme.fontWeight.bold};
	color: ${({ theme }) => theme.colors.formFontDarkBlue};
	margin: 0 auto;
	text-align: center;
	@media(min-width: 768px) {
		font-size: 16px;
		line-height: 20px;
		padding-top: 10px;
	}
`

const ErrorValidation = styled.p`
	font-size: 16px;
	display:none;
	color: ${({ theme }) => theme.colors.formAlwisOrange};
	position: absolute;
	transform: translateY(152px);
	text-align: center;
	&:nth-of-type(1){
		display:block;
	}
	@media (max-width:1150px){
		&:nth-of-type(1){
			transform: translateY(324px);
		}
	}
	@media (max-width:1023px){
		&:nth-of-type(1){
			transform: translateY(152px);
		}
	}
	@media (max-width:789px){
		&:nth-of-type(1){
			transform: translateY(324px);
		}
	}
	@media (max-width:540px){
		&:nth-of-type(1){
			transform: translateY(207px);
		}
	}
	@media (max-width:413px){
		&:nth-of-type(1){
			transform: translateY(275px);
		}
	}
	@media (max-width:374px){
		&:nth-of-type(1){
			transform: translateY(236px);
		}
	}

`


const ErrorNone = styled.span`
visibility: hidden;
position:absolute;
`;


const SingleTiltReduxForm = ({ input, meta: { error, touched }, tiltIcon, tiltName, id, activeId, onClick: handleTiltId, isNotBrand, htmlId, props }) => {
	return (
		<>
			<TiltWrapper {...input} id={htmlId} NotBrand={isNotBrand}
				className={activeId === id ? "active tilt" : "tilt"} key={id} onClick={() => handleTiltId(id)}>
				<TiltIcon className={isNotBrand ? 'not-brand' : ''} src={tiltIcon} />
				<TiltName>{tiltName}</TiltName>
				{/* <ErrorValidation>{props.meta && props.meta.error && props.meta.touched
			 (<div>{props.meta.error}</div>)
			 }
			</ErrorValidation> */}

			</TiltWrapper>
			{touched && ((error && <ErrorValidation> <ErrorNone> {error}</ErrorNone>pole wymagane</ErrorValidation>))}
		</>


	)
}

export default SingleTiltReduxForm
