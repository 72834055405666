import React, { useEffect, useState, Component } from "react"
import styled from "styled-components"
import { Header } from "../common/Header"
import { CategoryHeader } from "../common/CategoryHeader"
import PreviousStepButton from "../common/PreviousStepButton"
import NextStepButton from "../common/NextStepButton"
import { connect, useDispatch, useSelector } from "react-redux"
import { ContentWrapper } from "../common/ContentWrapper";
import { navigate } from "gatsby";
import { NavigationButtonsWrapper } from "../common/NavigationButtonsWrapper";
import Logo from "../../images/SideStepsPreview/alwis-logo.png";
import { getSearchParams, useQueryParam } from "gatsby-query-params";
import { required } from "../commonReduxFormComponents/Validators"
// import RadioGroup from "../commonReduxFormComponents/RadioGroup"

import {
	buildYearData,
	houseTypeData,
	wallsConstructionTypeData,
	lastRenovationDateData,
	propertyTypeData,
	roofTypeData,
	flatFloorData,
	propertyTypesPictograms,
	otherPropertyTypesPictograms,
	wallsConstructionPictograms,
	beginningDateOfBuilding,
	expectedBuildingFinishDate, progressOfWorkData, propertyTypeTooltip, flatFloorTooltip, roofTypeTooltip,
	wallsConstructionypeTooltip, buildYearTooltip, propertyAreaTooltip, zipCodeTooltip, wallsConstructionTypeTooltip
} from "../../dataSources/Page1DataSource";
import {
	BlueHint,
	TiltsWrapper,
	ErrorMessageText,
	TabWrapper,
	InvisibleWrapper,
	HeaderAndQuestionMarkWrapper,
	currentDate,
	calendarDateFormat,
	RadioButtonsTwoOptionsWrapper,
	RadioButtonsWrapper, HeaderAndRadioButtonsWrapper
} from "../sharedComponents/SharedComponents";
import { fetchCitiesAction } from "../../state/cities";
import cleanLocalstorage from "../../utils/cleanLocalstorage";
import { toggleModalVisibility } from "../../state/showLoadDataModal";
import { toggleNotSellingModalVisibility } from "../../state/showNotSellingModal";
import Tooltip from "../tooltip/Tooltip";
import CircleWithQuestionMark from "../tooltip/CircleWithQuestionMark";
import Geocode from "react-geocode";
import { getDataFromFortWebApiAction } from "../../state/getDataFromLead";
import { Field, reduxForm, change, initialize, registerField, formValueSelector, getFormValues } from 'redux-form'
import SingleTextInput from "../commonReduxFormComponents/SingleTextInput";
import CustomSelectReduxForm from "../commonReduxFormComponents/CustomSelectReduxForm";
import CustomCheckboxTiltReduxform from "../commonReduxFormComponents/CustomCheckboxTiltReduxForm";
import SingleTiltReduxForm from "../commonReduxFormComponents/SingleTiltReduxForm";
import { toggleBlockAnyDataLoading } from "../../state/loadDataFromLocalStorage";
import SingleRadioButtonTwoOptionsReduxForm from "../commonReduxFormComponents/SingleRadioButtonTwoOptionsReduxForm";
import axios from "axios";
import {changeLead} from "../../state/lead";
import {sendDataToFortWebApiAction} from "../../state/sendDataToFortWebApi";

const LogoContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	width: 100%;
	height: 68px;
	margin-bottom: 30px;
	position: relative;
	@media(min-width: 768px) {
		display: none;
	}
`;

const LogoWrapper = styled.div`
	width: 160px;
	height: 57px;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	background-image: url( ${({ bgImg }) => bgImg});
`;


const SectionSeparationWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	width: 100%;
	margin: 30px 0;
`

const CheckboxesWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	//margin-top: -15px;
	margin-bottom: 50px;
	flex-wrap: wrap;
	&.first-page{
		justify-content:flex-start;
	}
	&.margin-top {
		margin-top: -48px;
	}
		div {
		&:nth-child(2n) {
			margin-right: 0;
		}
	}
	@media(min-width: 414px) {
		justify-content: flex-start;
		div {
			&:nth-child(2n) {
				margin-right: 10px;
			}
			&:nth-child(3n) {
				margin-right: 0;
			}
		}
	}
	@media(min-width: 535px) {
		justify-content: flex-start;
		div {
			&:nth-child(2n) {
				margin-right: 16px;
			}
			&:nth-child(3n) {
				margin-right: 16px;
			}
		}
	}
	@media(min-width: 768px) {
		div {
			&:nth-child(2n) {
				margin-right: 20px;
			}
			&:nth-child(3n) {
				margin-right: 20px;
			}
			&:nth-child(4n) {
				margin-right: 0;
			}
			&:nth-child(5n) {
				margin-right: 20px;
			}
			&:nth-child(6n) {
				margin-right: 20px;
			}
		}
	}
	@media(min-width: 928px) {
		div {
			&:nth-child(4n) {
				margin-right: 20px;
			}
			
		}
	}
	@media(min-width: 1024px) {
		margin-bottom: 60px;
	}
`;

const SectionSeparation = styled.div`
	display: flex;
	width: 100%;
	height: 3px;
	background-color: ${({ theme }) => theme.colors.formFontDarkBlue};
	@media(min-width: 414px) {
		//max-width: 353px;
	}
	@media(min-width: 536px) {
		//max-width: 476px;
	}
	@media(min-width: 768px) {
		max-width: 700px;
	}
`



let CleanFirstStep = ({ handleSubmit, value, load, pristine, reset, submitting, houseType,
	roofType, wallsConstructionType, buildYear,
	damageAmountLastYear, propertyType, change, lead, errors, zipCode, isOCInsuranceInPrivateLife }) => {
	const dispatch = useDispatch()
	const formReducer = useSelector(state => state.form)
	const cityReducer = useSelector(state => state.cities);
	const city = cityReducer.data.city;
	const isDataLoadFromLocalStorage = useSelector(state => state.loadDataFromLocalStorage.loadDataFromLocalstorage);
	const blockAnyDataLoading = useSelector(state => state.loadDataFromLocalStorage.blockAnyDataLoading);
	useEffect(() => {
		if (city) {
			change('home[address[city]]', city)
		}
	}, [city])


	//TODO
	// 1. jeżeli jest lead w url to załadowanie danych z api i zainicjowanie formularza
	// 2. jeżeli nie ma lead url to sprawdzenie czy jest propertyForm w localstorage i uzupełnienie wartościami z localstorage
	// 3. wyświetlenie pustego formularza, jeśli nie mma danych z backendu ani w localstorage

	let leadUuid = useQueryParam('lead', '');
	useEffect(() => {
		if(leadUuid) {
			if(!blockAnyDataLoading) {
				dispatch(getDataFromFortWebApiAction(leadUuid)).then(res =>
					{
						if (res.value.status === 200) { // formularz istnieje i udostępnia dane
							dispatch(changeLead(res.value.data.lead.uuid));
							dispatch(initialize('propertyForm', res.value.data))
							setBarn(res.value.data.home.barn)
							setOutbuilding(res.value.data.home.outbuilding)
							setStable(res.value.data.home.stable)
							setCowshed(res.value.data.home.cowshed)
							setDifferentBuilding(res.value.data.home.differentBuilding)
						} else if (res.value.status === 204) { // formularz istnieje, ale nie udostępnia danych klienta
							dispatch(initialize('propertyForm', null))
						}
					}
				)
			}
		} else {
			const localstorageItemExists = ("propertyForm" in localStorage && localStorage.getItem("propertyForm") !== "" && !(localStorage.getItem("propertyForm") === "null"))
			if(!localstorageItemExists) {
				dispatch(initialize('propertyForm', null)) // nie ma leada ani localstorage - załadowanie pustego formularza
				change('lead[agentSymbol]', "ALWIS");
			} else {
				if (isDataLoadFromLocalStorage && !blockAnyDataLoading) { // załadowanie tylko 1 raz na pierwszej stronie
					let dataFromLocalstorage = localStorage.getItem('propertyForm'); // załadowanie danych z localstorage do formularza
					const parsedLocalstorage = dataFromLocalstorage ? JSON.parse(dataFromLocalstorage) : '';
					dispatch(initialize('propertyForm', parsedLocalstorage))
					change('lead[agentSymbol]', "ALWIS");
					setBarn(parsedLocalstorage.home.barn)
					setOutbuilding(parsedLocalstorage.home.outbuilding)
					setStable(parsedLocalstorage.home.stable)
					setCowshed(parsedLocalstorage.home.cowshed)
					setDifferentBuilding(parsedLocalstorage.home.differentBuilding)
				}
			}
		}
	}, [leadUuid, isDataLoadFromLocalStorage]);

	Geocode.setApiKey("AIzaSyAwN2JTbVVPXo28KggLshKczB-cCXxoUuE");
	Geocode.setLanguage("pl");
	Geocode.setRegion("pl");

	const commonFormReducerValues = (formReducer?.propertyForm?.values?.home) || '';
	const [barn, setBarn] = useState(commonFormReducerValues?.barn);
	const [outbuilding, setOutbuilding] = useState(commonFormReducerValues?.outbuilding);
	const [stable, setStable] = useState(commonFormReducerValues?.stable);
	const [cowshed, setCowshed] = useState(commonFormReducerValues?.cowshed);
	const [differentBuilding, setDifferentBuilding] = useState(commonFormReducerValues?.differentBuilding);

	const handleBarn = () => {
		setBarn(!barn);
		dispatch(change("home[barn]", !barn));
	}
	const handleOutbuilding = () => {
		setOutbuilding(!outbuilding);
		dispatch(change("home[outbuilding]", !outbuilding));
	}
	const handleStable = () => {
		setStable(!stable);
		dispatch(change("home[stable]", !stable));
	}
	const handleCowshed = () => {
		setCowshed(!cowshed);
		dispatch(change("home[cowshed]", !cowshed));
	}
	const handleDifferentBuilding = () => {
		setDifferentBuilding(!differentBuilding);
		dispatch(change("home[differentBuilding]", !differentBuilding));
	}


	const [propertyTypeTooltipVisible, togglePropertyTypeVisibility] = useState(false)
	const handlePropertyTypeTooltipVisibility = () => {
		togglePropertyTypeVisibility(!propertyTypeTooltipVisible)
		flatFloorVisibility(false)
		roofTypeVisibility(false)
		wallsConstructionTypeVisibility(false)
		toogleBuildYearVisibility(false)
		togglePropertyAreaVisibility(false)
		toggleZipCodeVisibility(false)
	}

	const [flatFloorTooltipVisible, flatFloorVisibility] = useState(false)
	const handleFlatFloorTooltipVisibility = () => {
		flatFloorVisibility(!flatFloorTooltipVisible)
		togglePropertyTypeVisibility(false)
		roofTypeVisibility(false)
		wallsConstructionTypeVisibility(false)
		toogleBuildYearVisibility(false)
		togglePropertyAreaVisibility(false)
		toggleZipCodeVisibility(false)
	}

	const [roofTypeTooltipVisible, roofTypeVisibility] = useState(false)
	const handleRoofTypeTooltipVisibility = () => {
		roofTypeVisibility(!roofTypeTooltipVisible)
		togglePropertyTypeVisibility(false)
		flatFloorVisibility(false)
		wallsConstructionTypeVisibility(false)
		toogleBuildYearVisibility(false)
		togglePropertyAreaVisibility(false)
		toggleZipCodeVisibility(false)
	}

	const [wallsConstructionTypeTooltipVisible, wallsConstructionTypeVisibility] = useState(false)
	const handleWallsConstructionTypeTooltipVisibility = () => {
		wallsConstructionTypeVisibility(!wallsConstructionTypeTooltipVisible)
		togglePropertyTypeVisibility(false)
		flatFloorVisibility(false)
		roofTypeVisibility(false)
		toogleBuildYearVisibility(false)
		togglePropertyAreaVisibility(false)
		toggleZipCodeVisibility(false)
	}

	const [buildYearTooltipVisible, toogleBuildYearVisibility] = useState(false)
	const handleBuildYearTooltipVisibility = () => {
		toogleBuildYearVisibility(!buildYearTooltipVisible)
		togglePropertyTypeVisibility(false)
		flatFloorVisibility(false)
		roofTypeVisibility(false)
		wallsConstructionTypeVisibility(false)
		togglePropertyAreaVisibility(false)
		toggleZipCodeVisibility(false)
	}

	const [propertyAreaTooltipVisible, togglePropertyAreaVisibility] = useState(false)
	const handlePropertyAreaTooltipVisibility = () => {
		togglePropertyAreaVisibility(!propertyAreaTooltipVisible)
		togglePropertyTypeVisibility(false)
		flatFloorVisibility(false)
		roofTypeVisibility(false)
		wallsConstructionTypeVisibility(false)
		toogleBuildYearVisibility(false)
		toggleZipCodeVisibility(false)
	}

	const [zipCodeTooltipVisible, toggleZipCodeVisibility] = useState(false)
	const handleZipCodeTooltipVisibility = () => {
		toggleZipCodeVisibility(!zipCodeTooltipVisible)
		togglePropertyTypeVisibility(false)
		flatFloorVisibility(false)
		roofTypeVisibility(false)
		wallsConstructionTypeVisibility(false)
		toogleBuildYearVisibility(false)
		togglePropertyAreaVisibility(false)
	}


	class RadioGroup extends Component {
		render() {
			const { input, meta, options } = this.props
			const hasError = meta.touched && meta.error;

			return (
				<div>
					{options.map(o => <label key={o.value}><input type="radio" {...input} value={o.value} checked={o.value === input.value} /> {o.title}</label>)}
					{hasError && <span className="error">{meta.error}</span>}
				</div>
			);
		}
	}




	return (
		<TabWrapper>
			<LogoContainer>
				<LogoWrapper bgImg={Logo} />
			</LogoContainer>
			<Header>Znajdź najlepszą ofertę ubezpieczenia domu lub mieszkania</Header>
			<BlueHint>wypełnij formularz</BlueHint>
			<ContentWrapper>
				<form onSubmit={handleSubmit}>
					<InvisibleWrapper>
						<Field
							name="lead[agentSymbol]"
							component={SingleTextInput}
							type="text"
							editableInputHeader={"Lead"}
							placeholder={"Wpisz lead'a"}
						/>
					</InvisibleWrapper>
					<HeaderAndQuestionMarkWrapper>
						<CategoryHeader>Rodzaj nieruchomości</CategoryHeader>
						<CircleWithQuestionMark
							showCircle={true}
							isWindowVisible={propertyTypeTooltipVisible}
							onClick={handlePropertyTypeTooltipVisibility}
							tooltipHeader={propertyTypeTooltip.header}
							tooltipDescription={propertyTypeTooltip.description} />
					</HeaderAndQuestionMarkWrapper>
					<TiltsWrapper>
						{propertyTypeData.map(({ label, value }, key) => (
							<Field name="home[propertyType]"
								id={value}
								key={value}
								htmlId={'property-type-' + value}
								//  validate={required} //test
								component={SingleTiltReduxForm}
								activeId={propertyType}
								tiltIcon={propertyTypesPictograms[key]}
								validate={required}
								tiltName={label}
								isNotBrand={false}
								onClick={(val) => {
									change('home[propertyType]', val)
									// change('propertyTypeLabel', label)
									// changeFieldValue
									//  fields.propertyForRent.onChange(val)
									// dispatch(change('propertyForm', 'propertyForRent', val))
									if (val === "Mieszkanie") {
										if (houseType !== null) {
											change("home[houseType]", null)
										}
										if (roofType !== null) {
											change("home[roofType]", null)
										}
										if (wallsConstructionType !== null) {
											change("home[wallsConstructionType]", null)
										}
									}
								}} />


						))}

					</TiltsWrapper>

					<InvisibleWrapper isVisible={propertyType === "Dom" || propertyType === "Dom w budowie" || propertyType === "Gospodarstwo rolne"}>
						<Field
							name="home[houseType]"
							component={CustomSelectReduxForm}
							type="text"
							options={houseTypeData}
							// validate={validate.isRequired}
							selectLabel={"Rodzaj zabudowy budynku mieszkalnego"}
							placeholder={"Wybierz rodzaj"}
						/>
					</InvisibleWrapper>

					<InvisibleWrapper isVisible={propertyType === "Gospodarstwo rolne"}>
						<CategoryHeader className="margin-top">Inne budynki wchodzące w skład gospodarstwa rolnego</CategoryHeader>
						<CheckboxesWrapper className="first-page">
							<Field
								name="home[barn]"
								component={CustomCheckboxTiltReduxform}
								index={1}
								imageSource={otherPropertyTypesPictograms[0]}
								checked={barn}
								tiltText="Stodoła"
								onClick={handleBarn}
							/>
							<Field
								name="home[outbuilding]"
								component={CustomCheckboxTiltReduxform}
								index={2}
								imageSource={otherPropertyTypesPictograms[1]}
								checked={outbuilding}
								tiltText="Budynek gospodarczy"
								onClick={handleOutbuilding}
							/>
							<Field
								name="home[stable]"
								component={CustomCheckboxTiltReduxform}
								index={3}
								imageSource={otherPropertyTypesPictograms[2]}
								checked={stable}
								tiltText="Stajnia"
								onClick={handleStable}
							/>
							<Field
								name="home[cowshed]"
								component={CustomCheckboxTiltReduxform}
								index={4}
								imageSource={otherPropertyTypesPictograms[3]}
								checked={cowshed}
								tiltText="Obora"
								onClick={handleCowshed}
							/>
							<Field
								name="home[differentBuilding]"
								component={CustomCheckboxTiltReduxform}
								index={5}
								imageSource={otherPropertyTypesPictograms[4]}
								checked={differentBuilding}
								tiltText="Inne"
								onClick={handleDifferentBuilding}
							/>
						</CheckboxesWrapper>

					</InvisibleWrapper>





					<InvisibleWrapper isVisible={propertyType === "Mieszkanie"}>
						<Field
							name="home[address[flatFloor]]"
							component={CustomSelectReduxForm}
							type="text"
							options={flatFloorData}
							// validate={validate.isRequired}
							selectLabel={"Piętro mieszkania"}
							placeholder={"Wybierz piętro mieszkania"}
							showCircle={true}
							isWindowVisible={flatFloorTooltipVisible}
							onClick={handleFlatFloorTooltipVisibility}
							tooltipHeader={flatFloorTooltip.header}
							tooltipDescription={flatFloorTooltip.description}
						/>
					</InvisibleWrapper>
					<InvisibleWrapper isVisible={houseType}>
						<Field
							name="home[roofType]"
							component={CustomSelectReduxForm}
							type="text"
							options={roofTypeData}
							validate={validate.isRequired}
							selectLabel={"Pokrycie dachu"}
							placeholder={"Wybierz pokrycie dachu"}
							showCircle={true}
							isWindowVisible={flatFloorTooltipVisible}
							onClick={handleFlatFloorTooltipVisibility}
							tooltipHeader={roofTypeTooltip.header}
							tooltipDescription={roofTypeTooltip.description}
						/>
					</InvisibleWrapper>
					<InvisibleWrapper isVisible={roofType}>

						<Field
							name="home[wallsConstructionType]"
							component={CustomSelectReduxForm}
							type="text"
							options={wallsConstructionTypeData}
							// validate={validate.isRequired}
							selectLabel={"Konstrukcja ścian zewnętrznych"}
							placeholder={"Wybierz konstrukcje ścian zewnętrznych"}
							showCircle={true}
							isWindowVisible={wallsConstructionTypeTooltipVisible}
							onClick={handleWallsConstructionTypeTooltipVisibility}
							tooltipHeader={wallsConstructionTypeTooltip.header}
							tooltipDescription={wallsConstructionTypeTooltip.description}
						/>
					</InvisibleWrapper>
					<InvisibleWrapper isVisible={propertyType && propertyType !== "Dom w budowie"}>
						<Field
							name="home[buildYear]"
							component={CustomSelectReduxForm}
							type="text"
							options={buildYearData}
							// validate={validate.isRequired}
							selectLabel={"Rok budowy"}
							placeholder={"Wybierz rok budowy"}
							showCircle={true}
							isWindowVisible={buildYearTooltipVisible}
							onClick={handleBuildYearTooltipVisibility}
							tooltipHeader={buildYearTooltip.header}
							tooltipDescription={buildYearTooltip.description}
						/>
					</InvisibleWrapper>
					<InvisibleWrapper isVisible={propertyType && propertyType === "Dom w budowie"}>
						<Field
							name="home[beginningDateOfBuilding]"
							component={CustomSelectReduxForm}
							type="text"
							options={beginningDateOfBuilding}
							// validate={validate.isRequired}
							selectLabel={"Rok rozpoczęcia budowy"}
							placeholder={"Wybierz rok rozpoczęcia budowy"}
						/>
					</InvisibleWrapper>
					<InvisibleWrapper isVisible={propertyType && propertyType === "Dom w budowie"}>
						<Field
							name="home[expectedBuildingFinishDate]"
							component={CustomSelectReduxForm}
							type="text"
							options={expectedBuildingFinishDate}
							// validate={validate.isRequired}
							selectLabel={"Planowana data zakończenia budowy"}
							placeholder={"Wybierz rok zakończenia budowy"}
						/>
					</InvisibleWrapper>
					<InvisibleWrapper isVisible={propertyType && propertyType === "Dom w budowie"}>
						<Field
							name="home[progressOfWork]"
							component={CustomSelectReduxForm}
							type="text"
							options={progressOfWorkData}
							// validate={validate.isRequired}
							selectLabel={"Stopień zaawansowania robót"}
							placeholder={"Wybierz stopień robót"}
						/>
					</InvisibleWrapper>
					<InvisibleWrapper isVisible={propertyType && propertyType !== "Dom w budowie" && (parseInt(buildYear?.label) <= 2015)}>
						<Field
							name="home[lastRenovationDate]"
							component={CustomSelectReduxForm}
							type="text"
							options={lastRenovationDateData}
							// validate={validate.isRequired}
							selectLabel={"Data ostatniego remontu generalnego"}
							placeholder={"Wybierz datę remontu"}
						/>

					</InvisibleWrapper>
					<Field
						name="home[propertyArea]"
						component={SingleTextInput}
						type="number"
						// validate={validate.isPropertyTypeAndAreaOk(propertyType)}
						editableInputHeader={"Powierzchnia"}
						placeholder={"Wpisz powierzchnię"}
						unitName="m2"
						showCircle={true}
						isWindowVisible={propertyAreaTooltipVisible}
						onClick={handlePropertyAreaTooltipVisibility}
						tooltipHeader={propertyAreaTooltip.header}
						tooltipDescription={propertyAreaTooltip.description}
					/>
					<SectionSeparationWrapper>
						<BlueHint className="section-title">Lokalizacja nieruchomości</BlueHint>
						<SectionSeparation />
					</SectionSeparationWrapper>
					<Field
						name="home[address[zipCode]]"
						component={SingleTextInput}
						// type="number"
						// validate={validate.isRequired}
						editableInputHeader={"Kod pocztowy"}
						placeholder={"__-___"}
						unitName="m2"
						isZipCodeMask={true}
						validate={required} //test
						showCircle={true}
						isWindowVisible={zipCodeTooltipVisible}
						onClick={handleZipCodeTooltipVisibility}
						tooltipHeader={zipCodeTooltip.header}
						tooltipDescription={zipCodeTooltip.description}
						onChange={(event) => {
							// change(event);
							const inputValue = event.target.value;
							const re = /^[0-9]{2}-[0-9]{3}$/;
							if (re.test(inputValue)) {
								const parsedZipCode = inputValue.replace('-', '');
								dispatch(fetchCitiesAction(parsedZipCode))
							}
						}}
					/>
					<Field
						name="home[address[city]]"
						component={SingleTextInput}
						type="text"
						// withRef
						// innerRef={formikRef}
						// innerRef={commentBox => this.city = commentBox.getRenderedComponent()}
						// validate={validate.isRequired}
						editableInputHeader={"Miejscowość"}
						placeholder={"Wpisz nazwę miejscowości"}
					/>
					<Field
						name="home[address[street]]"
						component={SingleTextInput}
						type="text"
						// validate={validate.isRequired}
						editableInputHeader={"Ulica"}
						placeholder={"Wpisz nazwę ulicy"}
					/>
					<Field
						name="home[address[buildingNumber]]"
						component={SingleTextInput}
						type="text"
						// validate={validate.isRequired}
						editableInputHeader={"Numer budynku"}
						placeholder={"Wpisz numer budynku"}
					/>
					<InvisibleWrapper isVisible={propertyType === "Mieszkanie"}>
						<Field
							name="home[address[flatNumber]]"
							component={SingleTextInput}
							type="number"
							// validate={validate.isRequired}
							editableInputHeader={"Numer mieszkania"}
							placeholder={"Wpisz numer mieszkania"}
						/>
					</InvisibleWrapper>

					<NavigationButtonsWrapper>
						<PreviousStepButton isVisible={false} textBtn={"Poprzedni krok"} />
						<NextStepButton type="submit" isVisible={true}
							isDisabled={submitting} textBtn={"DALEJ"} />
					</NavigationButtonsWrapper>
				</form>
				{/*</Formik>*/}

			</ContentWrapper>
		</TabWrapper>
	)
}

const onSubmit = (values, dispatch, formReducer) => {
	Geocode.fromAddress(values.home.address.zipCode).then(
		response => {
			const { lat, lng } = response.results[0].geometry.location;
			dispatch(change("propertyForm", "home[address[lat]]", lat))
			dispatch(change("propertyForm", "home[address[lng]]", lng))
		},
		error => {
			console.error(error);
		}
	);

	// localStorage.setItem('propertyForm', JSON.stringify(values));
	// registerField('propertyForm', 'street', 'string')
	// localStorage.setItem('form-data', JSON.parse(values));

	// alert(JSON.stringify(formReducer.values))
	console.log(formReducer)
	localStorage.setItem('propertyForm', JSON.stringify(values));
	// dispatch(sendDataToFortWebApiAction(JSON.stringify(values)))
	// dispatch(togglePopupVisibility(true))
	// alert(JSON.stringify(values))
	// console.log(values.flatFloor.value);
	//TODO podmiana wszystkich wartości które mają value i label na jedynie value
	// dispatch(change('propertyForm', 'flatFloor', values.flatFloor.value))
	// alert(JSON.stringify(values))
	dispatch(sendDataToFortWebApiAction(values)).then(res => {
			if (res.value.status === 201) { // formularz istnieje i udostępnia dane
				const uuid = res?.value?.data?.leadUuid || '';
				dispatch(changeLead(uuid));
				dispatch(change("propertyForm", "lead[uuid]", uuid))
			}
		}
	)

	dispatch(toggleBlockAnyDataLoading(true))
	// dispatch(sendDataToFortWebApiAction(JSON.stringify(formReducer.values)))
	navigate('/step-2')
	console.log(JSON.stringify(values))
}




const validate = (values, id) => {
	const errors = {
		"lead": {},
		"person": {},
		"home": {
			"address": {},
		},
	};
	// console.log(values);
	// if (!id.propertyType == "property-type-1" || !id.propertyType === "property-type-2" || !id.propertyType === "property-type-3" || !id.propertyType === "property-type-4") {
	// 	errors.propertyType = 'pole wymagane';
	// }
	if (!values?.home?.propertyType) {
		errors.home.propertyType = 'pole wymagane';
	}
	if (values?.home?.propertyType === "Dom" || values?.home?.propertyType === "Dom w budowie" || values?.home?.propertyType === "Gospodarstwo rolne") {
		if (!values?.home?.houseType) {
			errors.home.houseType = 'pole wymagane';
		}
		if (!values?.home?.roofType) {
			errors.home.roofType = 'pole wymagane';
		}
		if (!values?.home?.wallsConstructionType) {
			errors.home.wallsConstructionType = 'pole wymagane';
		}
	}
	if (values?.home?.propertyType === "Mieszkanie") {
		if (!values?.home?.address?.flatNumber) {
			errors.home.address.flatNumber = 'pole wymagane';
		}
		if (!values?.home?.address?.flatFloor) {
			errors.home.address.flatFloor = 'pole wymagane';
		}
	}
	if (values?.home?.propertyType === "Dom w budowie") {
		if (!values?.home?.beginningDateOfBuilding) {
			errors.home.beginningDateOfBuilding = 'pole wymagane'
		}
		if (!values?.home?.expectedBuildingFinishDate) {
			errors.home.expectedBuildingFinishDate = 'pole wymagane'
		}
		if (!values?.home?.progressOfWork) {
			errors.home.progressOfWork = 'pole wymagane'
		}
	} else {
		if (!values?.home?.buildYear) {
			errors.home.buildYear = 'pole wymagane'
		}
	}

	if (!values?.home?.propertyArea) {
		errors.home.propertyArea = 'pole wymagane'
	}
	if (values?.home?.propertyType !== "Dom w budowie" && (parseInt(values.buildYearLabel) <= 2015)) {
		if (!values?.home?.lastRenovationDate) {
			errors.home.lastRenovationDate = 'pole wymagane'
		}
	}
	if (!values?.home?.address?.zipCode) {
		errors.home.address.zipCode = 'pole wymagane'
	}
	if (!values?.home?.address?.city) {
		if (!values?.home?.address?.zipCode) {
			errors.home.address.city = 'pole wymagane'
		} else {
			errors.home.address.city = 'wprowadź poprawny kod pocztowy'
		}
	}
	if (!values?.home?.address?.street) {
		errors.home.address.street = 'pole wymagane'
	}
	if (!values?.home?.address?.buildingNumber) {
		errors.home.address.buildingNumber = 'pole wymagane'
	}
	console.log('page1localstorage ' + JSON.stringify(errors))
	return errors;

}

CleanFirstStep = reduxForm({
	form: 'propertyForm',
	destroyOnUnmount: false,
	keepDirtyOnReinitialize: true,
	validate,
	// forceUnregisterOnUnmount: true,
	onSubmit
})(CleanFirstStep);

const selector = formValueSelector('propertyForm')
CleanFirstStep = connect(state => {
	// can select values individually
	const isOCInsuranceInPrivateLife = selector(state, 'home[isOCInsuranceInPrivateLife]')
	const propertyArea = selector(state, 'home[propertyArea]')
	const damageAmountLastYear = selector(state, 'home[damageAmountLastYear]')
	const propertyType = selector(state, 'home[propertyType]')
	const houseType = selector(state, 'home[houseType]')
	const roofType = selector(state, 'home[roofType]')
	const wallsConstructionType = selector(state, 'home[wallsConstructionType]')
	const buildYear = selector(state, 'home[buildYear]')
	const city = selector(state, 'home[address[city]]')
	const zipCode = selector(state, 'home[address[zipCode]]')
	// values: getFormValues('propertyForm')(state)
	// const favoriteColorValue = selector(state, 'favoriteColor')
	// or together as a group
	// const { firstName, lastName } = selector(state, 'firstName', 'lastName')
	const lead = state.lead.lead;
	return {
		propertyArea,
		damageAmountLastYear,
		propertyType,
		houseType,
		roofType,
		wallsConstructionType,
		buildYear,
		city,
		zipCode,
		isOCInsuranceInPrivateLife,
		lead
		// favoriteColorValue,
		// fullName: `${firstName || ''} ${lastName || ''}`
	}
})(CleanFirstStep)

export default CleanFirstStep;

