import React from "react"
import {useSelector} from "react-redux";
import CleanFirstStep from "../components/firstStep/CleanFirstStep";

const IndexPage = () => {
	const isDataLoadFromLocalStorage = useSelector(state => state.loadDataFromLocalStorage.loadDataFromLocalstorage);

	return (
		<>
			<CleanFirstStep/>
		</>
	)
};

export default IndexPage
