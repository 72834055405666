import House  from "../images/PropertyTypes/dom_1.svg";
import  Flat  from "../images/PropertyTypes/mieszkanie_1.svg";
import  PropertyNotFinished  from "../images/PropertyTypes/nieruchomosc_w_budowie_1.svg"
import  Farm from "../images/PropertyTypes/gospodarstwo_rolne_1.svg";
import Flammable from "../images/WallConstructionTypes/palne .svg";
import Nonflammable from "../images/WallConstructionTypes/niepalne_1.svg";

import Barn from "../images/NewPictograms/barn.svg";
import Outbuilding from "../images/NewPictograms/outbuilding.svg";
import Stable from "../images/NewPictograms/stable.svg";
import Cowshed from "../images/NewPictograms/cowshed.svg";
import DifferentBuilding from "../images/NewPictograms/differentBuilding.svg";

export const propertyTypeData = [{
	"value": "Dom", // 1
	"label": "Dom"
}, {
	"value": "Mieszkanie", // 2
	"label": "Mieszkanie"
},
{
	"value": "Dom w budowie", // 3
	"label": "Dom w budowie"
},{
	"value": "Gospodarstwo rolne", // 4
	"label": "Gospodarstwo rolne"
}]

export const propertyTypesPictograms = [House, Flat, PropertyNotFinished, Farm];

export const otherPropertyTypesPictograms = [Barn, Outbuilding, Stable, Cowshed, DifferentBuilding];

export const houseTypeData = [{
	"value": 1,
	"label": "Dom jednorodzinny wolnostojący"
},
{
	"value": 2,
	"label": "Dom jednorodzinny w zabudowie bliźniaczej"
},
{
	"value": 3,
	"label": "Dom jednorodzinny w zabudowie szeregowej"
},
{
	"value": 4,
	"label": "Dom jednorodzinny w zabudowie atrialnej"
},
{
	"value": 5,
	"label": "Dom jednorodzinny w zabudowie grupowej"
}]
export const roofTypeData = [{
	"value": 1,
	"label": "Blacha, blachodachówka"
},
{
	"value": 2,
	"label": "Dachówka ceramiczna, dachówka cementowa"
},
{
	"value": 3,
	"label": "Papa, gont bitumiczny"
},
{
	"value": 4,
	"label": "Gont drewniany"
},
{
	"value": 5,
	"label": "Strzecha, słoma"
},
{
	"value": 6,
	"label": "Inne"
},]
export const wallsConstructionTypeData = [{
	"value": 1,
	"label": "Konstrukcja niepalna"
}, {
	"value": 2,
	"label": "Konstrukcja palna"
}]

export const wallsConstructionPictograms = [Nonflammable, Flammable];

export const buildYearData = []
for (let i = 2020, index = 1; i >1945; i-- ,index++){
	buildYearData.push({
		"value": index,
		"label": i.toString()
	})
}

buildYearData.push({
	"value": 75,
	"label": "1945 lub starszy"
})


export const lastRenovationDateData = []
for (let i = 2015, index = 1; i >1945; i-- ,index++){
	lastRenovationDateData.push({
		"value": index,
		"label": i.toString()
	})
}

export const expectedBuildingFinishDate = []
for (let i = 2020, index = 1; i < 2023; i++, index++) {
	expectedBuildingFinishDate.push({
		"value": index,
		"label": i.toString()
	})
}

expectedBuildingFinishDate.push({
	"value": 4,
	"label": "2023 lub późniejszy"
})

export const beginningDateOfBuilding = []
for (let i = 2020, index = 1; i >2010; i-- ,index++){
	beginningDateOfBuilding.push({
		"value": index,
		"label": i.toString()
	})
}

beginningDateOfBuilding.push({
	"value": 10,
	"label": "2010 lub wcześniejszy"
})


export const progressOfWorkData = [{
	"value": 1,
	"label": "Fundamenty"
},
{
	"value": 2,
	"label": "Stan surowy otwarty"
},
{
	"value": 3,
	"label": "Stan surowy zamknięty"
},
{
	"value": 4,
	"label": "Prace wykończeniowe"
}]

export const flatFloorData = [{
	"value": 1,
	"label": "Parter"
},
{
	"value": 2,
	"label": "Piętro pośrednie"
},
{
	"value": 3,
	"label": "Ostatnie piętro"
}]

export const propertyTypeTooltip = {
	"header": "Rodzaj nieruchomości",
	"description": "Wybierz czy chcesz ubezpieczyć dom czy mieszkanie. Jeśli nieruchomość nie uzyskała jeszcze pozwolenia na użytkowanie wybierz odpowiedź dom w budowie. Jeżeli chcesz ubezpieczyć gospodarstwo rolne, wybierz ostatnią opcję."
}

export const flatFloorTooltip = {
	"header": "Piętro mieszkania",
	"description": "Ubezpieczyciele pytają, na którym piętrze położone jest mieszkanie, ponieważ wpływa to m.in. na ryzyko zalania mieszkania."
}

export const roofTypeTooltip = {
	"header": "Pokrycie dachu",
	"description": "Jeśli pokrycia twojego dachu nie ma na liście skontaktuj się z nami telefonicznie. Pomożemy znaleźć odpowiednie ubezpieczenie Twojej nieruchomości."
}

export const wallsConstructionTypeTooltip = {
	"header": "Konstrukcja ścian zewnętrznych",
	"description": "Palna - wybierz tą odpowiedź, jeśli budynek zbudowano z bali bądź w technologii kanadyjskiej, skandynawskiej i tym podobnych.\n" +
		"\n" +
		"Niepalna - wybierz tą odpowiedź, gdy budynek zbudowano z cegły, pustaka, bloczków betonowych itp. (również, gdy więźba dachowa ma drewnianą konstrukcję).\n" +
		"\n" +
		"Jeśli konstrukcja budynku jest łączona bądź nie wiesz, jaką odpowiedź wybrać skontaktuj się z nami telefonicznie, pomożemy znaleźć odpowiednie ubezpieczenie."
}

export const buildYearTooltip = {
	"header": "Rok budowy",
	"description": "Jeśli nie znasz dokładnego roku budowy budynku wybierz orientacyjny rocznik.\n" +
		"\n" +
		"W przypadku, gdy budowa trwała więcej niż rok podaj datę oddania nieruchomości do użytkowania."
}

export const propertyAreaTooltip = {
	"header": "Powierzchnia",
	"description": "Podaj w metrach kwadratowych jaka jest powierzchnia użytkowa ubezpieczanej nieruchomości."
}

export const zipCodeTooltip = {
	"header": "Kod pocztowy",
	"description": "Pytamy o dokładny adres nieruchomości, ponieważ ubezpieczyciele wymagają tych informacji dla poprawnego wyliczenia wysokości składki.\n" +
		"W przypadku braku tej informacji, niektóre towarzystwa nie przedstawią ofert, dlatego wypełnienie danych adresowych jest obowiązkowe"
}


